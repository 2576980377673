import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import get from 'lodash/get'
import React from 'react'

// Open links in a new tab by forcing target="_blank"
const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <img
          src={get(node, 'data.target.fields.file.en-US.url')}
          alt={get(node, 'data.target.fields.file.en-US.title')}
          className="rich-text-image"
          style={{ marginBottom: 36 }}
        />
      )
    },
  },
}

export default ({ field }) => documentToReactComponents(field.json, options)
